import AuthService from "../services/auth.service";
import React, { Component } from "react";
import { Route, Routes, Navigate } from 'react-router-dom'

import BaseStyle from './Dashboard/Dashboard.module.css';

import Dashboard from './Dashboard/Dashboard/Dashboard';
import Notifications from './Dashboard/Notifications/Notifications';

import NavBar from '../components/NavBar/NavBar'
import Shortcut from '../components/Shortcut/Shortcut'


class DashboardRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: undefined,
      redirectToAuthentication: false,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (!user) {
      this.setState({
        currentUser: user,
        redirectToAuthentication: true,
      });
    } else {
      this.setState({
        currentUser: user,
      });
    }
  }

  render() {
    if (this.state.redirectToAuthentication) {
      return <Navigate to="/authentication/sign-in" />;
    }

    return (
      <div className={BaseStyle.dashboard}>
        <NavBar />
          <div className={BaseStyle.dashboardContainer}>
            <div className={BaseStyle.content}>
            <Routes>
              <Route path="" element={<Dashboard />} />
              <Route path="notifications" element={<Notifications />} />
            </Routes>
            </div>
          </div>
        <Shortcut />
      </div>
    );
  }
}

export default DashboardRoute;