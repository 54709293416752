import React from "react";
import NavBarElement from './NavBarElement'

import AccountIcon from '../../assets/images/account.svg';
import LiveIcon from '../../assets/images/live.svg';
import NotificationsIcon from '../../assets/images/notifications.svg';
import RestitutionsIcon from '../../assets/images/restitutions.svg';
import SystemIcon from '../../assets/images/system.svg';

import NavBarStyle from './NavBar.module.css';

const NavBar = (props) => {
  return (
    <nav className={NavBarStyle.nav}>
      <NavBarElement route={'/restitutions'} title={'Restitutions'} icon={RestitutionsIcon} routes={['/restitutions']} />
      <NavBarElement route={'/system'} title={'Système'} icon={SystemIcon} routes={['/system']} />
      <NavBarElement route={'/live'} title={'Live'} icon={LiveIcon} routes={['/live', '/']} />
      <NavBarElement route={'/notifications'} title={'Notifications'} icon={NotificationsIcon} routes={['/notifications']} />
      <NavBarElement route={'/account'} title={'Compte'} icon={AccountIcon} routes={['/account']} />
    </nav>
  );
};

export default NavBar;