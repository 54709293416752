import React, { Component } from "react";
// import { Link, Navigate } from 'react-router-dom';

import BaseStyle from '../Dashboard.module.css';
import DashboardStyle from './Dashboard.module.css';
// import DashboardStyle from './Dashboard.module.css';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div className={DashboardStyle.content}>
        <span>Dashboard</span>

      </div>
    );
  }
}