import React from "react";

import InputBase from './Input.module.css';

const Input = (props) => {
  const { type, name, required, label, value, onChange } = props;

  return (
    <div className={InputBase.form}>
      { label && 
        <label className={InputBase.label} htmlFor={`form-${name}`}>
          {label}{required && '*'}
        </label>
      }
      <input 
        className={InputBase.input} 
        type={type} 
        name={`form-${name}`} 
        id={`form-${name}`} 
        required={required}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;