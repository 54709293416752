import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login(email, password) {
    return api
      .post("/client/auth/login", {
        email,
        password
      })
      .then(response => {
        console.log(response);
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  resetPasswordSend(email) {
    return api
    .post("/client/auth/reset-password-send", {
      email,
    })
    .then(response => {
      console.log(response);
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
  }

  register(username, email, password) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return TokenService.getUser();
  }
}

export default new AuthService();
