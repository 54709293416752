import React, { Component } from "react";
import { Link } from 'react-router-dom';

import AuthService from "../../../services/auth.service";

import BaseStyle from '../Authentication.module.css';
import ResetPasswordSendStyle from './ResetPasswordSend.module.css';

import Input from '../../../components/Form/Input.component'
import Button from '../../../components/Form/Button.component'

export default class ResetPasswordSend extends Component {
  constructor(props) {
    super(props);
    this.handleSendResetPasswordMail = this.handleSendResetPasswordMail.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);

    this.state = {
      email: "",
      loading: false,
      error: true,
      message: ""
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  handleSendResetPasswordMail(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });

    AuthService.resetPasswordSend(this.state.email).then(
      (value) => {
        this.setState({
          error: false,
          message: value.successMessage
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errorMessage) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          error: true,
          message: resMessage
        });
      }
    );
  }

  render() {
    return (
      <form className={ResetPasswordSendStyle.resetPasswordSend} onSubmit={this.handleSendResetPasswordMail}>
        <Input 
          type={'email'} 
          name={'email'} 
          required={true} 
          label={'Adresse email'} 
          value={this.state.email} 
          onChange={this.onChangeEmail}
        />

        {this.state.message && <span className={this.state.error ? BaseStyle.errorMessage : BaseStyle.successMessage}>{this.state.message}</span>} 

        <div className={ResetPasswordSendStyle.backToSignInContainer}>
          <Link className={ResetPasswordSendStyle.backToSignIn} to='/authentication/sign-in'>Retour à la connexion</Link>
        </div>

        <Button
          type={'submit'} 
          name={'Réinitialiser mon mot de passe'} 
          disabled={this.state.loading} 
        />
      </form>
    );
  }
}