import AuthService from "./services/auth.service";
import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import EventBus from "./common/EventBus";

import NavBar from "./components/NavBar/NavBar";
import Header from "./components/Header/Header";

import AuthenticationRoute from './pages/AuthenticationRoute'
import DashboardRoute from './pages/DashboardRoute'
import Dashboard from "./pages/Dashboard/Dashboard/Dashboard";


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div className="App">
        <BrowserRouter>
          {/* <Header /> */}
          <Routes>
            <Route path="/authentication/*" element={<AuthenticationRoute />} />
            <Route path="/account/*" element={<>Account</>} />
            <Route path="/dashboard/*" element={<DashboardRoute />} />
            <Route path="/" element={<DashboardRoute />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          {/* <NavBar currentUser={currentUser} showModeratorBoard={showModeratorBoard} showAdminBoard={showAdminBoard} /> */}
        </BrowserRouter>
      </div>
    );
  }
}

const NotFound = () => {
  return (
      <div>
          <h2>404 Not Found</h2>
          <p>Sorry, the page you are looking for does not exist.</p>
      </div>
  )
}

export default App;