import AuthService from "../services/auth.service";
import React, { Component } from "react";
import { Route, Routes, Navigate } from 'react-router-dom'

import SignIn from "../pages/Authentication/SignIn/SignIn";

import ResetPasswordSend from "../pages/Authentication/ResetPasswordSend/ResetPasswordSend";
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";

import BaseStyle from './Authentication/Authentication.module.css';

import logo from '../assets/images/logo_w4s.jpg';

class AuthenticationRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      redirectToHome: false,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    console.log(user);

    if (user) {
      this.setState({
        currentUser: user,
        redirectToHome: true,
      });
    }
  }

  render() {
    if (this.state.redirectToHome) {
      return <Navigate to="/" />;
    }

    return (
      <div className={BaseStyle.authentication}>
        <div className={BaseStyle.modal}>
          <Routes>
            <Route path="sign-in" element={<SignIn />} />
            <Route path="reset-password-send" element={<ResetPasswordSend />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Routes>

          <div className={BaseStyle.logo_bg}>
            <img className={BaseStyle.logo} src={logo} alt="Logo watch4safe"/>
          </div>
        </div>

        <div className={BaseStyle.version}>
          <span>v0.0.1</span>
        </div>
      </div>
    );
  }
}

export default AuthenticationRoute;