import React, { Component } from "react";
import { Link, Navigate } from 'react-router-dom';

import AuthService from "../../../services/auth.service";

import BaseStyle from '../Authentication.module.css';
import SignInStyle from './SignIn.module.css';

import Input from '../../../components/Form/Input.component'
import Button from '../../../components/Form/Button.component'

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
      redirect: false
    };
  }

  componentDidMount(){
    document.title = "Watch4Safe - Espace de connexion"
  }
  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });

    AuthService.login(this.state.email, this.state.password).then(
      () => {
        this.setState({
          redirect: true,
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    );
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    }

    return (
      <form className={SignInStyle.signIn} onSubmit={this.handleLogin}>
        <Input
          type={'email'} 
          name={'email'} 
          required={true} 
          label={'Adresse email'} 
          value={this.state.email} 
          onChange={this.onChangeEmail}
        />

        <Input 
          type={'password'} 
          name={'password'} 
          required={true} 
          label={'Mot de passe'} 
          value={this.state.password} 
          onChange={this.onChangePassword}
        />
        {/* Adresse e-mail ou mot de passe incorrect. */}
        {this.state.message && <span className={BaseStyle.errorMessage}>{this.state.message}</span>} 

        <div className={SignInStyle.lostPasswordContainer}>
          <Link className={SignInStyle.lostPassword} to='/authentication/reset-password-send'>Mot de passe oublié ?</Link>
        </div>

        <Button
          type={'submit'} 
          name={'Me connecter'} 
          disabled={this.state.loading} 
        />
      </form>
    );
  }
}