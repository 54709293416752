import React, { Component } from "react";
// import { Link, Navigate } from 'react-router-dom';

import BaseStyle from '../Dashboard.module.css';
// import DashboardStyle from './Notifications.module.css';

export default class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {

    return (
      <div className={BaseStyle.content}>
        <span>Notifications</span>
      </div>
    );
  }
}