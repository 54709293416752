import React from "react";

import ShortcutStyle from './Shortcut.module.css';

const Shortcut = () => {

  return (
    <div className={ShortcutStyle.Shortcut}>
      <span>shortcut</span>
    </div>
  );
};

export default Shortcut;