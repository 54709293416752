import React, { Component } from "react";

import AuthService from "../../../services/auth.service";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    // this.form.validateAll();
    AuthService.login(this.state.username, this.state.password).then(
      () => {
        this.props.history.push("/profile");
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(resMessage);

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    );
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <form onSubmit={this.handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Mot de passe</label>
              <input type="email" className="form-control" id="email" name="email" value={this.state.username} onChange={this.onChangeUsername} required />
            </div>

            <div className="form-group">
              <label htmlFor="password">Confirmation du mot de passe</label>
              <input type="password" className="form-control" id="password" name="password" value={this.state.password} onChange={this.onChangePassword} required />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}
