import React from "react";
import { Link, useLocation } from "react-router-dom";

import NavBarElementStyle from './NavBarElement.module.css';

const NavBarElement = (props) => {
  const location = useLocation();
  const { route, icon, title, routes } = props;

  return (
    <Link to={route} className={`nav-element ${routes.includes(location.pathname) ? 'active' : ''}`}>
      <div className="icons">
        <div className="icon">
          <img className="icon-image" src={icon} alt={title} />
        </div>
        <span className="title">{title}</span>
      </div>
      <div className="tag-selected"></div>
    </Link>
  );
};

export default NavBarElement;